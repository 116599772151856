<template>
  <div class="footer position-relative">
    <div class="uk-grid-collapse" uk-grid>
      <div class="uk-width-expand@s uk-first-column d-flex align-item-center">
        <small><img :src="logoFooter" width="120" alt=""> © <strong>Softinya</strong>. {{
          $t('general.all_rights_reserved') }}.</small>
      </div>
      <div class="mt-2 mr-4">
        <a href="">{{ $t('general.clarification_Text') }}</a>
      </div>
      <div class="uk-width-auto@s">
        <nav class="footer-nav-icon">
          <ul v-if="subdomain !== 'next4bizacademy.com'">
            <li><a href="https://tr.linkedin.com/company/anzeraogrenmeteknolojileri/" target="_blank"><i
                  class="icon-brand-linkedin"></i></a></li>
            <li><a href="https://www.instagram.com/anzeraofficial/" target="_blank"><i
                  class="icon-brand-instagram"></i></a></li>
            <li><a
                href="https://tr-tr.facebook.com/pages/category/Computer-Company/Anzera-%C3%96%C4%9Frenme-Teknolojileri-601130579942407/"
                target="_blank"><i class="icon-brand-facebook"></i></a></li>
            <li><a href="https://www.youtube.com/channel/UCYlNDP2rbHqxD95wbsOSyKw" target="_blank"><i
                  class="icon-brand-youtube"></i></a></li>
          </ul>
          <ul v-else>
            <li><a href="https://www.linkedin.com/company/next4biz/" target="_blank"><i
                  class="icon-brand-linkedin"></i></a></li>
            <li><a href="https://www.instagram.com/next4biz/" target="_blank"><i class="icon-brand-instagram"></i></a>
            </li>
            <li><a href="https://www.facebook.com/next4biz" target="_blank"><i class="icon-brand-facebook"></i></a></li>
            <li><a href="https://www.youtube.com/@next4biz" target="_blank"><i class="icon-brand-youtube"></i></a></li>
          </ul>
        </nav>
      </div>

      <div style="position:relative;">
        <div class="c-share">
          <input class="c-share__input" type="checkbox" id="checkbox" ref="checkbox">
          <label class="c-share__toggler" for="checkbox">
            <i class="uil-plus"></i>
          </label>

          <ul class="c-share_options" data-title="Hızlı Oluştur">
            <li>
              <router-link :to="{ name: 'company.question.add' }">Soru Ekle</router-link>
            </li>
            <!-- <li>
              <router-link :to="{ name: 'company.institutions.add' }">Kurum Ekle</router-link>
            </li> -->
            <!-- <li>
              <router-link :to="{ name: 'company.companies.add' }">Firma Ekle</router-link>
            </li> -->
            <li>
              <router-link :to="{ name: 'company.user.add' }">Kullanıcı Ekle</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.content.add' }">İçerik Ekle</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.program.add' }">Program Ekle</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.assignments.add' }">Atama Yap</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.content.catalog.add' }">Katalog Eğitimi Ekle</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.survey.add' }">Anket Ekle</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.certificate.add' }"> {{ $t('certificate.add') }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.certificate_template.add' }">{{ $t('certificate_template.add')
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.advertisement.add' }"> Reklam Ekle </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.announcement.add' }">Duyuru Oluştur</router-link>
            </li>
          </ul>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "CompanyFooter",
  props: {
    showMenu: {
      required: false,
      default: true
    },
  },
  data() {
    return {
      subdomain: null
    }
  },
  methods: {
    toggle() {
      let el = this.$refs.checkbox
      el.checked = false
    }
  },
  computed: {
    logoFooter() {
      return process.env.BASE_URL + "media/images/new-logo-dark.svg";
    }
  },
  mounted() {
    this.subdomain = window.location.hostname
  },
  watch: {
    $route() {
      this.toggle();
    }
  }
}
</script>

<style scoped>
@media (max-width: 768px) {
  .c-share {
    right: 0 !important;
  }
}

.c-share {
  position: fixed;
  max-width: inherit;
  bottom: 50%;
  width: 3.4375em;
  height: 3.4375em;
  z-index: 999;
  position: fixed;
  right: 47px;
  opacity: 1;
  bottom: 35px;
  margin: 0 32px 0 0;
  z-index: 999;
  -webkit-transition: 0.35s;
  transition: 0.35s;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

.c-share__input {
  display: none;
}

.c-share__input:checked~.c-share__toggler .c-share__icon {
  transition: 0s;
  width: 0;
  height: 0;
  color: #639eff;
}

.c-share__input:checked~.c-share__toggler .c-share__icon::before {
  transform: rotate(-45deg);
}

.c-share__input:checked~.c-share__toggler .c-share__icon::after {
  transform: rotate(45deg);
}

.c-share__input:checked~.c-share_options {
  width: 15.0625em;
  height: auto;
  border-radius: 0.3125em;
  bottom: 0;
}

.c-share_options li:hover {
  color: #000;
  font-weight: 500;
}

.c-share__input:checked~.c-share_options::before,
.c-share__input:checked~.c-share_options li {
  transition: 0.3s 0.15s;
  opacity: 1;
  transform: translateY(0);
  font-size: 13px;
}

.c-share__toggler,
.c-share_options {
  position: absolute;
  right: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-color: #f2f2f2;
}

.c-share__toggler {
  cursor: pointer;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-share__icon {
  position: relative;
  left: -0.3125em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  color: #029dfc;
  background-color: currentColor;
  box-shadow: 0.625em -0.625em, 0.625em 0.625em;
  transition: 0.1s 0.05s;
}

.c-share__icon::before,
.c-share__icon::after {
  content: "";
  position: absolute;
  width: 1em;
  height: 0.125em;
  background-color: currentColor;
  transition: 0.1s;
}

.c-share__icon::before {
  top: 0.1875em;
  left: 0.1875em;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.c-share__icon::after {
  top: -0.125em;
  left: 0.03125em;
  transform-origin: center;
  transform: rotate(-45deg);
}

.c-share_options {
  list-style: none;
  margin: 0;
  padding: 1.0625em 1.25em !important;
  box-sizing: border-box;
  overflow: hidden;
  transition: 0.2s;
  box-shadow: 0 0.125em 0.125em rgba(0, 0, 0, 0.3);
}

.c-share_options::before {
  content: attr(data-title);
  display: block;
  margin-bottom: 1.25em;
  font-weight: 700;
}

.c-share_options li {
  font-size: 0.875em;
  color: #464646;
  cursor: pointer;
}

.c-share_options li:not(:last-child) {
  margin-bottom: 0.75em;
}

.c-share_options::before,
.c-share_options li {
  opacity: 0;
  transform: translateY(0.625em);
  transition: 0s;
}
</style>